.button {
    --height: 2.5rem;
    box-sizing: border-box;
    grid-auto-flow: column;
    justify-content: center;
    align-content: center;
    width: fit-content;
    border-radius: calc(var(--height) / 2);
    text-decoration: none;
    height: var(--height);
    padding: 0.5rem 1rem;
}

.button {
    --height: 2.5rem;
    box-sizing: border-box;
    grid-auto-flow: column;
    justify-content: center;
    align-content: center;
    width: fit-content;
    border-radius: calc(var(--height) / 2);
    text-decoration: none;
    height: var(--height);
    padding: 0 1.5rem;
}

.button > span.material-symbols-rounded {
    font-size: 1.125rem;
    align-self: center;
}

.button.start:has(span.material-symbols-rounded) {
    gap: 0.5rem;
    padding: 0 1.5rem 0 1rem;
}

.button.end:has(span.material-symbols-rounded) {
    gap: 0.5rem;
    padding: 0 1rem 0 1.5rem;
}



.button.elevated {
    background-color: color-mix(in srgb, var(--surface-tint), var(--surface-container-low) 8%);
    color: var(--on-primary-container);
    box-shadow: var(--elevation-1);
    font: var(--label_large-font);
    letter-spacing: var(--label_large-letter-spacing);
 }

.button.elevated:hover {
    background-color: color-mix(in srgb, var(--surface-tint), var(--surface-container-low) 10%);
    box-shadow: var(--elevation-2);
}

.button.elevated:focus {
    background-color: color-mix(in srgb, var(--surface-tint), var(--surface-container-low) 12%);
    box-shadow: var(--elevation-1);
}

.button.elevated.disabled {
    background-color: var(--disabled-backgound-color);
    color: var(--disabled-color);
    box-shadow: none;
}


.button.filled {
    background-color: var(--primary-container);
    color: var(--on-primary-container);
    font: var(--label_large-font);
    letter-spacing: var(--label_large-letter-spacing);
}

.button.filled:hover {
    background-color: color-mix(in srgb, var(--primary-container), var(--surface-container-low) 8%);
    box-shadow: var(--elevation-1);
}

.button.filled:focus {
    background-color: color-mix(in srgb, var(--primary-container), var(--surface-container-low) 10%);
}

.button.filled.disabled {
    background-color: var(--disabled-backgound-color);
    color: var(--disabled-color);
}


.button.filled-tonal {
    background-color: var(--primary);
    color: var(--on-primary);
    font: var(--label_large-font);
    letter-spacing: var(--label_large-letter-spacing);
}

.button.filled-tonal:hover {
    background-color: color-mix(in srgb, var(--primary), var(--surface-container-low) 8%);
    box-shadow: var(--elevation-1);
}

.button.filled-tonal:focus {
    background-color: color-mix(in srgb, var(--primary-container), var(--surface-container-low) 12%);
}

.button.filled-tonal.disabled {
    background-color: var(--disabled-backgound-color);
    color: var(--disabled-color);
}


.button.outlined {
    border: 1px solid var(--outline);
    color: var(--primary);
    font: var(--label_large-font);
    letter-spacing: var(--label_large-letter-spacing);
}

.button.outlined:hover {
    background-color: color-mix(in srgb, var(--primary), var(--white) 8%);
}

.button.outlined:focus {
    background-color: color-mix(in srgb, var(--primary), var(--white) 10%);
}

.button.outlined.disabled {
    border: 1px solid var(--disabled-backgound-color);
    color: var(--disabled-color);
}


.button.text {
    color: var(--primary);
    font: var(--label_large-font);
    letter-spacing: var(--label_large-letter-spacing);
}

.button.text:hover {
    background-color: color-mix(in srgb, var(--primary), var(--white) 8%);
}

.button.text:focus {
    background-color: color-mix(in srgb, var(--primary), var(--white) 10%);
}

.button.text.disabled {
    color: var(--disabled-color);
}