:root {
    --white: #FFFFFF;
    --black: #000000;

    --primary-fixed: #D8E2FF;
    --on-primary-fixed: #001A43;
    --primary-fixed-dim: #AEC6FF;
    --on-primary-fixed-varient: #004397;

    --secondary-fixed: #DBE2F9;
    --on-secondary-fixed: #141B2C;
    --secondary-fixed-dim: #BFC6DC;
    --on-secondary-fixed-varient: #3F4759;

    --shadow: #000000;
    --scrim: #000000;

    --shadow-light: hsla(0, 0%, 0%, 15%);
    --shadow-dark: hsla(0, 0%, 0%, 30%);

    --elevation-1: 0px 1px 2px 0px var(--shadow-light), 0px 1px 3px 1px var(--shadow-dark);
    --elevation-2: 0px 1px 2px 0px var(--shadow-light), 0px 2px 6px 2px var(--shadow-dark);
    --elevation-3: 0px 1px 3px 0px var(--shadow-light), 0px 4px 8px 3px var(--shadow-dark);
    --elevation-4: 0px 2px 3px 0px var(--shadow-light), 0px 6px 10px 4px var(--shadow-dark);
    --elevation-5: 0px 4px 4px 0px var(--shadow-light), 0px 8px 12px 6px var(--shadow-dark);

    --display_large-font: 400 3.5rem/4rem "Quicksand", sans-serif;
    --display_large-letter-spacing: -0.015rem;
    --display_medium-font: 400 2.75rem/3.25rem "Quicksand", sans-serif;
    --display_medium-letter-spacing: 0;
    --display_small-font: 400 2.25rem/2.75rem "Quicksand", sans-serif;
    --display_small-letter-spacing: 0;

    --headline_large-font: 400 2rem/2.5rem "Quicksand", sans-serif;
    --headline_large-letter-spacing: 0;
    --headline_medium-font: 400 1.75rem/2.25rem "Quicksand", sans-serif;
    --headline_medium-letter-spacing: 0;
    --headline_small-font: 400 1.5rem/2rem "Quicksand", sans-serif;
    --headline_small-letter-spacing: 0;

    --title_large-font: 400 1.375rem/1.75rem "Quicksand", sans-serif;
    --title_large-letter-spacing: 0;
    --title_medium-font: 500 1rem/1.5rem "Quicksand", sans-serif;
    --title_medium-letter-spacing: 0.01rem;
    --title_small-font: 500 0.875rem/1.25rem "Quicksand", sans-serif;
    --title_small-letter-spacing: 0.006rem;

    --body_large-font: 400 1rem/1.5rem "Quicksand", sans-serif;
    --body_large-letter-spacing: 0.03rem;
    --body_medium-font: 400 0.875rem/1.25rem "Quicksand", sans-serif;
    --body_medium-letter-spacing: 0.015rem;
    --body_small-font: 400 0.75rem/1rem "Quicksand", sans-serif;
    --body_small-letter-spacing: 0.025rem;

    --label_large-font: 500 0.875rem/1.25rem "Quicksand", sans-serif;
    --label_large-letter-spacing: 0.00625rem;
    --label_medium-font: 500 0.75rem/1rem "Quicksand", sans-serif;
    --label_medium-letter-spacing: 0.03125rem;
    --label_small-font: 500 0.6875rem/1rem "Quicksand", sans-serif;
    --label_small-letter-spacing: 0.03125rem;
}

body[data-theme="light"] {
    --primary: #0059C5;
    --on-primary: #FFFFFF;
    --primary-container: #D8E2FF;
    --on-primary-container: #001A43;
    --primary-fixed: #D8E2FF;
    --on-primary-fixed: #001A43;
    --primary-fixed-dim: #AEC6FF;
    --on-primary-fixed-varient: #004397;
    --inverse-primary: #AEC6FF;

    --secondary: #575E71;
    --on-secondary: #FFFFFF;
    --secondary-container: #DBE2F9;
    --on-secondary-container: #141B2C;


    --error: #BA1A1A;
    --on-error: #FFFFFF;
    --error-container: #FFDAD6;
    --on-error-container: #410002;

    --outline: #757780;
    --outline-varient: #C5C6D0;

    --background: #FEFBFF;
    --on-background: #1B1B1F;

    --surface: #FAF8FD;
    --on-surface: #1B1B1F;
    --surface-varient: #E1E2EC;
    --on-surface-varient: #44474F;
    --surface-tint: #0059C5;
    --inverse-surface: #303034;
    --inverse-on-surface: #F2F0F4;

    --surface-container-highest: #E3E2E6;
    --surface-container-high: #E9E7EC;
    --surface-container: #EFEDF1;
    --surface-container-low: #F5F3F7;
    --surface-container-lowest: #FFFFFF;
    --surface-container-bright: #FAF8FD;
    --surface-container-dim: #DBD9DD;

    --disabled-backgound-color: rgba(27, 27, 31, 12%);
    --disabled-color: rgba(27, 27, 31, 38%);
}

body[data-theme="dark"] {
    --primary: #AEC6FF;
    --on-primary: #002E6B;
    --primary-container: #004397;
    --on-primary-container: #D8E2FF;

    --inverse-primary: #AEC6FF;

    --secondary: #BFC6DC;
    --on-secondary: #293041;
    --secondary-container: #3F4759;
    --on-secondary-container: #DBE2F9;


    --error: #FFB4AB;
    --on-error: #690005;
    --error-container: #93000A;
    --on-error-container: #FFDAD6;

    --outline: #8E9099;
    --outline-varient: #44474F;

    --background: #1B1B1F;
    --on-background: #E3E2E6;

    --surface: #121316;
    --on-surface: #C7C6CA;
    --surface-varient: #44474F;
    --on-surface-varient: #C5C6D0;
    --surface-tint: #AEC6FF;
    --inverse-surface: #E3E2E6;
    --inverse-on-surface: #1B1B1F;

    --surface-container-highest: #343538;
    --surface-container-high: #292A2D;
    --surface-container: #1F1F23;
    --surface-container-low: #1B1B1F;
    --surface-container-lowest: #0D0E11;
    --surface-container-bright: #38393C;
    --surface-container-dim: #121316;

    --disabled-backgound-color: rgba(199, 198, 202, 12%);
    --disabled-color: rgba(199, 198, 202, 38%);
}

body {
    background-color: var(--surface);
}