#hero {
    background-color: var(--primary-container);
    grid-template-columns: 1fr 1fr;
    height: 85vh;
    padding: 5rem 5vw 0;
}

#hero #left-side {
    padding: 1rem;
    align-content: center;
    justify-content: center;
    gap: 1.5rem;
}

#hero #right-side {
    justify-content: center;
    align-content:center;
}

#right-side img {
    height: 30rem;
    transform: scaleX(-1);
}

#hero h1 {
    font: var(--display_large-font);
    letter-spacing: var(--display_large-letter-spacing);
    color: var(--black);
}

#hero p {
    font: var(--title_large-font);
    letter-spacing: var(--title_large-letter-spacing);
}