body {
  background-color: var(--surface);
    margin: 0;
    padding: 0;
}

a {
  cursor: pointer;
}

body * {
  font-family: "Quicksand", "sans-serif";
  margin: 0;
  padding: 0;
  display: grid;
  box-sizing: border-box;
}

.material-symbols-rounded {
  font-variation-settings:
          'FILL' 0,
          'wght' 400,
          'GRAD' 0,
          'opsz' 24
}

h2 {
    font: var(--headline_large-font);
    letter-spacing: var(--headline_large-letter-spacing);
}

section {
    padding: 2rem 5vw;
    gap: 0.5rem;
}

.section-header {
    margin-block-end: 1rem;
}