.card {
    border-radius: 0.75rem;
    display: grid;
    padding: 1rem;
    min-height: 10rem;
    gap: 1rem;
    align-items: start;
    align-content: space-between;
}

.card.elevated {
    background-color: color-mix(in srgb, var(--primary-container), var(--surface-container-low) 8%);
    box-shadow: var(--elevation-1);
}

.card.elevated:hover {
    box-shadow: var(--elevation-2);
}

.card.elevated:focus {
    background-color: color-mix(in srgb, var(--primary-container), var(--surface-container-low) 12%);
}

.card.elevated.disabled {
    background-color: var(--surface);
    opacity: 38%;
}


.card.filled {
    background-color: color-mix(in srgb, var(--primary-container), var(--surface-container-highest) 8%);
}

.card.filled:hover {
    background-color: color-mix(in srgb, var(--primary-container), var(--surface-container-highest) 8%);
}

.card.filled:focus {
    background-color: color-mix(in srgb, var(--primary-container), var(--surface-container-highest) 8%);
}

.card.filled.disabled {
    background-color: var(--surface-varient);
    opacity: 38%;
}


.card.outlined {
    background-color: color-mix(in srgb, var(--surface-tint) 8%, var(--surface));
    border: 1px solid var(--outline-varient);
}

.card.outlined:focus {
    border: 1px solid var(--on-surface);
}

.card.outlined.disabled {
    border: 1px solid rgba(68, 71, 79, 12%);
}

.card .title {
    font: var(--headline_medium-font);
    letter-spacing: var(--headline_medium-letter-spacing);
    margin-block-start: 0.5rem;
    font-weight: 600;
}

.card ul {
    margin-block-start: 0.5rem;
}

.card li {
    display: list-item;
    list-style-position: inside;
    margin-inline-start: 0.5rem;
}

.card li.bold {
    font-weight: 600;
}

.card .cta-container {
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    margin: 1rem 0 0;
    align-self: end;
}

.card .price {
    font: var(--headline_small-font);
    letter-spacing: var(--headline_small-letter-spacing);
}

.card .fine-print {
    font: var(--label_medium-font);
    letter-spacing: var(--label_medium-letter-spacing);
}

.card .button {
    justify-self: end;
}