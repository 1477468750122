nav {
    --height: 4em;
    justify-self: center;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    background-color: var(--primary);
    height: var(--height);
    border-radius: calc(var(--height) / 2);
    position: fixed;
    width: 90vw;
    padding: 0 1rem;
    margin-top: 1rem;
    z-index: 2;
}

nav .logo {
    height: 4rem;
    padding: 0.75rem 1rem;
}

.nav-list {
    grid-auto-flow: column;
    list-style-type: none;
    justify-content: center;
    align-content: center;
    gap: 2em;
}

.nav-link {
    text-decoration: none;
    color: var(--on-primary);
    font-size: 1rem;
    padding: 1.375em 1em;
}